<template>
    <div class="content">
        <div class="btn" style="display:flex;margin-bottom:20px"><Button type="primary" style="margin-left:auto" @click="openAddModal">新增</Button></div>
        <Table :loading="loading" :columns="talbeColumns" :data="tableData" height="700" border stripe></Table>
        <!-- <div class="left">
         <Tree :data="treeData" show-checkbox :load-data="loadData" multiple refs="tree" @on-toggle-expand="changeCheck" ></Tree>
     </div>
     <div class="center">&gt;</div>
     <div class="right">
         <div class="item" v-for="(item,index) in tableData" :key="index">{{item.wholeAddr}}</div>
     </div> -->
        <LiefengModal class="map" :fullscreen="false" width="400px" height="600px" title="选择服务范围" @input="mapFn" :value="mapStatus">
            <template #contentarea style="width: 100%;height: 700px;">
                <Tree :data="treeData" show-checkbox :load-data="loadData" multiple @on-check-change="changeCheck" ref="tree"></Tree>
            </template>
            <template #toolsbar>
                <Button
                    type="info"
                    style="margin-right: 10px"
                    @click="
                        mapTip = true
                        mapFn(false)
                    "
                >
                    取消
                </Button>
                <Button type="primary" @click="saveMap">确定</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
export default {
    props: {
        orgCode: String,
    },
    components: { LiefengModal },
    data() {
        return {
            tableData: [],
            treeData: [],
            itemTrue: false,
            talbeColumns: [
                {
                    title: "所属组织机构",
                    key: "wholeAddr",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    minWidth: 300,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: {},
                                    on: {
                                        click: () => {
                                            this.$Modal.confirm({
                                                title: "温馨提示",
                                                content: "<p>您正在删除当前数据，此操作不可逆，是否继续？</p>",
                                                onOk: () => {
                                                    this.$post("/gx/pc/organizationScope/deleteManageScope", {
                                                        orgCode: params.row.orgCode,
                                                        orgCodeSelf: this.orgCode,
                                                    }).then(res => {
                                                        if (res.code == 200) {
                                                            this.$Message.success({
                                                                content: "删除成功",
                                                                background: true,
                                                            })
                                                            this.getList()
                                                        } else {
                                                            this.$Message.error({
                                                                content: res.desc,
                                                                background: true,
                                                            })
                                                            return
                                                        }
                                                    })
                                                },
                                            })
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            mapStatus: false,
            itemList: [],
            loading: false,
        }
    },
    created() {},
    methods: {
        changeCheck() {
            this.itemList = []
            this.formatterTree(this.treeData)
        },
        formatterTree(list, model) {
            list.map(item => {
                if (item.checked == true) {
                    this.itemList.push(item.orgCode)
                } else {
                    if (item.children && item.children.length != 0) {
                        this.formatterTree(item.children)
                    }
                }
            })
        },
        // 方法部分
        mapFn(status) {
            if (!status) {
                this.mapStatus = status
            }
        },
        saveMap() {
            if (this.itemList.length == 0) {
                this.$Message.warning({
                    content: "服务范围不能为空",
                    background: true,
                })
                return
            }
            this.update()
        },
        // 点击新增按钮
        openAddModal() {
            this.mapStatus = true
            this.treeData = []
            this.getPulishData("44", "treeData")
        },
        // 树节点回调事件
        loadData(item, callback) {
            item.loading = true
            this.$get("/orgzz/pc/organization/selectOrganizationByOrgCode", {
                orgCode: item.orgCode,
            }).then(res => {
                item.loading = false
                if (res.code == 200 && res.dataList.length != 0) {
                    let arr = []
                    res.dataList.map(items => {
                        if (items.orgLevel != 5) {
                            arr.push({
                                value: items.orgPath,
                                title: items.orgName,
                                orgLevel: items.orgLevel,
                                orgCode: items.orgCode,
                                parentId: items.parentId,
                                orgId: items.orgId,
                                children: [],
                                loading: false,
                                checked: false,
                            })
                        } else {
                            arr.push({
                                value: items.orgPath,
                                title: items.orgName,
                                orgLevel: items.orgLevel,
                                orgCode: items.orgCode,
                                parentId: items.parentId,
                                orgId: items.orgId,
                                checked: false,
                            })
                        }
                    })
                    // console.log(this.treeData);
                    callback(arr)
                }
            })
        },
        // 接口部分
        getList() {
            this.loading = true
            this.$get("/gx/pc/organizationScope/selectManageScopeByOrgCodeSelf", {
                orgCodeSelf: this.orgCode,
            }).then(res => {
                this.loading = false
                if (res.code == 200) {
                    this.tableData = res.dataList
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                    this.tableData = []
                }
            })
        },
        getPulishData(code, list) {
            this.$get("/orgzz/pc/organization/selectOrganizationByOrgCode", {
                orgCode: code,
            }).then(res => {
                if (res.code == 200) {
                    this[list] = res.dataList.map(item => {
                        return {
                            value: item.orgPath,
                            title: item.orgName,
                            orgLevel: item.orgLevel,
                            orgCode: item.orgCode,
                            parentId: item.parentId,
                            orgId: item.orgId,
                            children: [],
                            loading: false,
                            checked: false,
                        }
                    })
                }
            })
        },
        update() {
            this.$post(
                "/gx/pc/organizationScope/saveOrgManageScopeList",
                {
                    orgCodeSelf: this.orgCode,
                    orgCodeList: this.itemList,
                    oemCode: parent.vue.oemInfo.oemCode,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "新增成功",
                        background: true,
                    })
                    this.getList()
                    this.mapStatus = false
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
    },
    watch: {
        orgCode: {
            handler(val, newVal) {
                if (val) this.getList()
            },
        },
    },
}
</script>

<style lang="less" scoped>
.content {
    width: 100%;
    height: 720px;
    .left {
        width: 40%;
        height: 100%;
        overflow-y: scroll;
        border: 1px solid #cccccc;
    }
    .center {
        width: 10%;
        height: 100%;
        text-align: center;
        line-height: 720px;
        color: #999999;
        font-size: 20px;
    }
    .right {
        width: 40%;
        height: 100%;
        overflow-y: scroll;
        border: 1px solid #cccccc;
        padding-left: 10px;
        .item {
            width: 100%;
            height: 40px;
            line-height: 40px;
            // border: 1px solid red;
        }
    }
}
</style>
